import { HEADER_TYPE, MENU_ITEMS } from "appConstants";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppMainHeaderWrapperType } from "types/headerCmp";
import useLogout from "utils/hooks/useLogout";

// App Module Color Mapping - [start]
const colorMapping: Record<HEADER_TYPE, string> = {
  [HEADER_TYPE.DASHBOARD]: "appModules.50",
  [HEADER_TYPE.FINANCE]: "appModules.300",
  [HEADER_TYPE.DEALERSHIP]: "appModules.100",
  [HEADER_TYPE.DEALERSHIP_SETTINGS]: "appModules.100",
  [HEADER_TYPE.INVENTORY]: "appModules.200",
  [HEADER_TYPE.PERSONNEL]: "appModules.400",
  [HEADER_TYPE.REPORTS]: "appModules.500",
  [HEADER_TYPE.PARTS_SERVICES]: "appModules.600",
  [HEADER_TYPE.TIME_CLOCK]: "appModules.700",
};

const colorSubMapping: Record<HEADER_TYPE, string> = {
  [HEADER_TYPE.DASHBOARD]: "appSubModules.50",
  [HEADER_TYPE.FINANCE]: "appSubModules.300",
  [HEADER_TYPE.DEALERSHIP]: "appSubModules.100",
  [HEADER_TYPE.DEALERSHIP_SETTINGS]: "appSubModules.100",
  [HEADER_TYPE.INVENTORY]: "appSubModules.200",
  [HEADER_TYPE.PERSONNEL]: "appSubModules.400",
  [HEADER_TYPE.REPORTS]: "appSubModules.500",
  [HEADER_TYPE.PARTS_SERVICES]: "appSubModules.600",
  [HEADER_TYPE.TIME_CLOCK]: "appSubModules.700",
};
// [end]

const useAppMainHeader = (props: AppMainHeaderWrapperType) => {
  const { appModuleName, leftPan, rightPan, cornerPan } = props;

  // Function to return the appropriate color based on the appModuleName - [start]
  // Generalized function to return the appropriate color based on the appModuleName and mapping
  const getModuleColor = (
    appModuleName: string | undefined,
    mapping: Record<string, string>,
    defaultColor: string = "appModules.100"
  ) => {
    return appModuleName ? mapping[appModuleName] || defaultColor : undefined;
  };

  const moduleColor = getModuleColor(appModuleName, colorMapping);
  const moduleSubColor = getModuleColor(appModuleName, colorSubMapping);
  // [end]

  //varaibles
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const headerMenuOpen = Boolean(anchorEl);

  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();

  const { handleLogout } = useLogout();

  //menu open - [start]
  const handleHeaderMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  // [end]

  //menu close - [start]
  const handleHeaderMenuClose = () => {
    setAnchorEl(null);
  };
  // [end]

  const handleNavigateToRoute = (route: string, mainMenuNav?: boolean) => {
    history.push(route);
    if (mainMenuNav) {
      // INFO: to call menu close function only when main menu is opened
      handleHeaderMenuClose();
    }
  };

  // Determine the active menu item
  const activeMenuItem = MENU_ITEMS.find(
    (item) =>
      currentPath.startsWith(item?.link) ||
      !!item.childLInks.find(
        (
          childItem // INFO: Checking wether child link parent matched the main menu item link and current path is same as child item link
        ) =>
          (childItem.parentLink === item?.link ||
            childItem.mainMenuLink === item?.link) &&
          currentPath === childItem.link
      )
  );

  // Submenu links for the matched app module
  const NavSubLinks = React.useMemo(() => {
    // Find the menu for the current appModuleName
    const matchedMenu = MENU_ITEMS.find((menu) => menu.label === appModuleName);

    if (!matchedMenu || !matchedMenu.childLInks) return [];

    // Filter valid child links (must have both label and link)
    return matchedMenu.childLInks
      .filter((link) => link.label && link.link)
      .map((link) => ({
        ...link,
        isActive:
          currentPath === link.link ||
          !!matchedMenu.childLInks.find(
            (item) => item.parentLink === link.link && currentPath === item.link
          ),
      }));
  }, [appModuleName, location]);

  return {
    activeMenuItem,
    moduleColor,
    moduleSubColor,
    headerMenuOpen,
    anchorEl,
    leftPan,
    rightPan,
    cornerPan,
    NavSubLinks,
    handleHeaderMenuClick,
    handleHeaderMenuClose,
    handleNavigateToRoute,
    handleLogout,
  };
};

export default useAppMainHeader;
