import { Box } from "@mui/material";
// import { Sidebar } from "components";
import AppAlert from "AlertWrapper";

//Application shell for logged in pages
const AppShell = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "greyVariantAlt.100",
        minHeight: "100vh",
      }}
    >
      {/* <Sidebar /> */}
      <Box
        className="app"
        component="main"
        sx={{ flexGrow: 1, overflow: "auto" }}
        role="main"
      >
        <AppAlert />
        {children}
      </Box>
    </Box>
  );
};

export default AppShell;
