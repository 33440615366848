import { createSlice } from "@reduxjs/toolkit";
import { CustomerState } from "./customer.model";
import {
  createCustomer,
  getBuyers,
  getAssociatedCustomers,
  getTags,
} from "./customer.action";
import {
  createCustomerFullfilled,
  createCustomerRejected,
  getBuyersFullfilled,
  getBuyersRejected,
  getAssociatedCustomersFullfilled,
  getAssociatedCustomersRejected,
  getTagsRejected,
  getTagsFullfilled,
} from "./customer.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: CustomerState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  totalRecords: 0,
  buyers: [],
  tags: [],
  createdCustomer: null,
  associatedCustomers: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(getBuyers.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getBuyers.fulfilled, (state, action) => {
      getBuyersFullfilled(state, action);
    }),
    builder.addCase(getBuyers.rejected, (state, action) => {
      getBuyersRejected(state, action);
    }),
    builder.addCase(createCustomer.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      createCustomerFullfilled(state, action);
    }),
    builder.addCase(createCustomer.rejected, (state, action) => {
      createCustomerRejected(state, action);
    }),
    builder.addCase(getAssociatedCustomers.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getAssociatedCustomers.fulfilled, (state, action) => {
      getAssociatedCustomersFullfilled(state, action);
    }),
    builder.addCase(getAssociatedCustomers.rejected, (state, action) => {
      getAssociatedCustomersRejected(state, action);
    }),
    builder.addCase(getTags.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getTags.fulfilled, (state, action) => {
      getTagsFullfilled(state, action);
    }),
    builder.addCase(getTags.rejected, (state, action) => {
      getTagsRejected(state, action);
    })
  ),
});

export const { resetSuccess, resetError, reset } = customerSlice.actions;
