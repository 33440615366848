import { MENU_ITEMS_TYPE } from "types";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalculateIcon from "@mui/icons-material/Calculate";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import WidgetsSharpIcon from "@mui/icons-material/WidgetsSharp";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import BuildCircleSharpIcon from "@mui/icons-material/BuildCircleSharp";
import DealerShipIcon from "components/DealershipIcon";
import SettingsIcon from "@mui/icons-material/Settings";

export const APP_ROUTES = {
  Home: "/",
  Login: "/login",
  CreateUser: "/createuser",
  ForgotPassword: "/reset-password",
  AuthenticateCode: "/authenticate-code",
  AssignRole: "/roles-access/assign",
  SecurityPersonnel: "/security-personnel",
  CreateRole: "/roles-access/create-role",
  EditRole: "/roles-access/edit-role",
  ViewSecurityPersonnel: "/security-personnel/view-security-personnel",
  EditSecurityPersonnel: "/security-personnel/create-security-personnel",
  ViewRole: "/roles-access/role",
  MergeSecurityPersonnel: "/security-personnel/merge-security-personnel",
  Logout: "/logout",
  ViewRoles: "/roles-access/roles",
  NotFound: "/not-found",
  Inventory: "/inventory",
  ExampleComp: "/components",
  Reports: "/reports",
  TimeClock: "/timeclock",
  AssetRecords: "/inventory/asset-records",
  DealershipRecords: "/dealership/dealership-records",
  CreateAsset: "/inventory/create-asset",
  EditAsset: "/inventory/edit-asset",
  AccountingOperations: "/inventory/asset/AccountingOperations",
  CreateDealership: "/dealership/create-dealership",
  EditDealership: "/dealership/edit-dealership",
  CreateSubGroupDealership: "/dealership/create-subgroup-dealership",
  ViewDealership: "/dealership/view-dealership",
  DealershipSettingGeneralInfo: "/dealership-setting/general-info",
  DealershipSettingStatuses: "/dealership-setting/statuses",
  DealershipSettingAccounts: "/dealership-setting/accounts",
  DealershipSettingVendors: "/dealership-setting/vendors",
  DealershipSettingAddOns: "/dealership-setting/add-ons",
  ViewAsset: "/inventory/view-asset",
  DuplicateAsset: "/inventory/duplicate-asset",
  ViewAssetHistory: "/inventory/view-asset-history",
  ViewAssetHistoryDetails: "/inventory/view-asset-history-details",
  TransactionListing: "/inventory/transaction-listing",
  DispatchRecords: "/inventory/dispatch-records",
  CreateOrEditDispatch: "/inventory/create-or-edit-dispatch",
  EditDispatchEvent: "/inventory/edit-dispatch-event",
  CancelDispatch: "/inventory/cacel-dispatch",
  ViewDispatch: "/inventory/view-dispatch",
  ViewDispatchHistory: "/inventory/view-dispatch-history",
  ViewDispatchCalendar: "/inventory/view-dispatch-calendar",
  PurchasingListing: "/inventory/purchasing",
  ViewTemplateItem: "/inventory/purchasing/viewTemplate",
  ViewPurchaseItem: "/inventory/view-purchase",
  CreatePurchaseOrder: "/inventory/create-purchase",
  EditPurchaseOrder: "/inventory/edit-purchase",
  FinanceQuotes: "/finance/quotes",
  FinanceCreateQuote: "/finance/quotes/create",
  FinanceEditQuote: "/finance/quotes/edit",
  FinanceViewQuote: "/finance/quotes/view",
  FinanceDeals: "/finance/deals",
  FinanceDeliveries: "/finance/deliveries",
};

export const MENU_ITEMS: Array<MENU_ITEMS_TYPE> = [
  {
    index: 0,
    label: "Dashboard",
    link: APP_ROUTES.ExampleComp,
    moduleIcon: DashboardSharpIcon,
    moduleColor: "appModules.50",
    childLInks: [],
    // showItem: false,
  },
  {
    index: 1,
    label: "Dealership",
    link: APP_ROUTES.DealershipRecords,
    moduleIcon: DealerShipIcon,
    moduleColor: "grey.50",
    childLInks: [
      { label: "Dealership", link: APP_ROUTES.DealershipRecords },
      {
        label: "Sub Group",
        link: APP_ROUTES.CreateSubGroupDealership,
        mainMenuLink: APP_ROUTES.DealershipRecords,
      },
      {
        link: APP_ROUTES.CreateDealership,
        parentLink: APP_ROUTES.DealershipRecords,
      },
      {
        link: APP_ROUTES.EditDealership,
        parentLink: APP_ROUTES.DealershipRecords,
      },
      {
        link: APP_ROUTES.ViewDealership,
        parentLink: APP_ROUTES.DealershipRecords,
      },
    ],
  },
  {
    index: 2,
    label: "Dealership Settings",
    link: APP_ROUTES.DealershipSettingGeneralInfo,
    moduleIcon: SettingsIcon,
    moduleColor: "grey.50",
    childLInks: [
      {
        label: "General Info",
        link: APP_ROUTES.DealershipSettingGeneralInfo,
      },
      {
        label: "Stages",
        link: APP_ROUTES.DealershipSettingStatuses,
        mainMenuLink: APP_ROUTES.DealershipSettingGeneralInfo,
      },
      {
        label: "Accounts",
        link: APP_ROUTES.DealershipSettingAccounts,
        mainMenuLink: APP_ROUTES.DealershipSettingGeneralInfo,
      },
      {
        label: "Vendors",
        link: APP_ROUTES.DealershipSettingVendors,
        mainMenuLink: APP_ROUTES.DealershipSettingGeneralInfo,
      },
      {
        label: "Add-Ons",
        link: APP_ROUTES.DealershipSettingAddOns,
        mainMenuLink: APP_ROUTES.DealershipSettingGeneralInfo,
      },
    ],
  },
  {
    index: 3,
    label: "Inventory",
    link: APP_ROUTES.AssetRecords,
    moduleIcon: WidgetsSharpIcon,
    moduleColor: "appModules.200",
    moduleSubColor: "appSubModules.200",
    childLInks: [
      { label: "Asset Records", link: APP_ROUTES.AssetRecords },
      { link: APP_ROUTES.CreateAsset, parentLink: APP_ROUTES.AssetRecords },
      {
        label: "Purchasing",
        link: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewPurchaseItem,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewTemplateItem,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        label: "Dispatch",
        link: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewDispatch,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.EditAsset,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewAsset,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.DuplicateAsset,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewAssetHistory,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewAssetHistoryDetails,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.TransactionListing,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.CreateOrEditDispatch,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewDispatchHistory,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewDispatchCalendar,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.CreatePurchaseOrder,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.EditPurchaseOrder,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.AccountingOperations,
        parentLink: APP_ROUTES.AssetRecords,
      },
    ],
  },
  {
    index: 4,
    label: "Finance",
    link: APP_ROUTES.FinanceQuotes,
    moduleIcon: CalculateIcon,
    moduleColor: "appModules.300",
    childLInks: [
      { label: "Quote", link: APP_ROUTES.FinanceQuotes },
      {
        link: APP_ROUTES.FinanceCreateQuote,
        parentLink: APP_ROUTES.FinanceQuotes,
      },
      {
        link: APP_ROUTES.FinanceEditQuote,
        parentLink: APP_ROUTES.FinanceQuotes,
      },
      {
        link: APP_ROUTES.FinanceViewQuote,
        parentLink: APP_ROUTES.FinanceQuotes,
      },
      {
        label: "Deal",
        link: APP_ROUTES.FinanceDeals,
        mainMenuLink: APP_ROUTES.FinanceQuotes,
      },
      {
        label: "Delivery",
        link: APP_ROUTES.FinanceDeliveries,
        mainMenuLink: APP_ROUTES.FinanceQuotes,
      },
    ],
  },
  {
    index: 5,
    label: "Personnel",
    link: APP_ROUTES.SecurityPersonnel,
    moduleIcon: AccountBoxIcon,
    moduleColor: "appModules.400",
    childLInks: [
      { label: "Personnel", link: APP_ROUTES.SecurityPersonnel },
      {
        label: "Roles & Access",
        link: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.EditSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.ViewSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.CreateRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.EditRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.AssignRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.ViewRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.MergeSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
    ],
  },
  {
    index: 6,
    label: "Reports",
    link: APP_ROUTES.Reports,
    moduleIcon: AnalyticsRoundedIcon,
    moduleColor: "appModules.500",
    childLInks: [
      { label: "Demo Link", link: "/" },
      { label: "Demo Link", link: "/" },
    ],
  },
  {
    index: 7,
    label: "Time Clock",
    link: APP_ROUTES.TimeClock,
    moduleIcon: QueryBuilderTwoToneIcon,
    moduleColor: "appModules.700",
    childLInks: [],
  },
  {
    index: 8,
    label: "Parts & Services",
    link: APP_ROUTES.ExampleComp,
    moduleIcon: BuildCircleSharpIcon,
    moduleColor: "appModules.600",
    childLInks: [],
  },
  {
    index: 999,
    label: "components",
    link: APP_ROUTES.ExampleComp,
    childLInks: [],
    showItem: false,
  },
];
