import { TextFieldProps, TextField, InputProps } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { textFieldStyle } from "appConstants/styles";
import { usePropagateRef } from "./usePropagateRef";
export type PerformantTextFieldProps = Omit<TextFieldProps, "name"> & {
  name: string;
  /**
   * IF true, it will use the traditional method for disabling performance
   */
  disablePerformance?: boolean;
  loading?: boolean;
  min?: number;
  max?: number;
  error?: boolean;
  touched?: boolean;
  value?: any;
  onChange?: any;
  helperText?: React.ReactNode;
  InputProps?: InputProps;
  isAmountType?: boolean;
  sx?: object;
  defaultStartValue?: string;
  isSetValueOnBlur?: boolean;
};
/**
 * This is kind of hacky solution, but it mostly works. Your mileage may vary
 */
const PerformantTextField: React.FC<PerformantTextFieldProps> = memo(
  (props) => {
    const {
      error,
      value,
      touched,
      onChange,
      helperText,
      InputProps,
      isAmountType = false,
      defaultStartValue,
      sx,
      isSetValueOnBlur = true,
    } = props;
    const field = { error, value, touched, onChange };
    /**
     * For performance reasons (possible due to CSS in JS issues), heavy views
     * affect re-renders (Formik changes state in every re-render), bringing keyboard
     * input to its knees. To control this, we create a setState that handles the field's inner
     * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
     * onFocus.
     */
    const [fieldValue, setFieldValue] = useState<string | number>(value);
    const { disablePerformance, loading, ...otherProps } = props;
    usePropagateRef({
      setFieldValue,
      name: props.name,
      value: value,
    });
    /**
     * Using this useEffect guarantees us that pre-filled forms
     * such as passwords work.
     */
    useEffect(() => {
      if (touched) {
        return;
      }
      if (value !== fieldValue) {
        if (isAmountType === true) {
          handlePatternUpdate(value);
        } else {
          setFieldValue(value);
        }
      }
      if (defaultStartValue) {
        setFieldValue(
          `${
            value.includes(defaultStartValue) ? "" : defaultStartValue
          }${value}`
        );
      }
      // eslint-disable-next-line
    }, [value, defaultStartValue]);

    const handlePatternUpdate = (value) => {
      if (value && typeof value === "string") {
        const numericValue = Number(value?.replace(/,/g, ""));
        if (!isNaN(numericValue)) {
          setFieldValue(numericValue.toLocaleString());
        } else {
          setFieldValue("");
        }
      } else {
        setFieldValue("");
      }
    };

    const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (isAmountType === true) {
        handlePatternUpdate(evt.target.value);
      } else if (defaultStartValue) {
        if (defaultStartValue.length - evt.target.value.length === 1) {
          return;
        }
        evt.target.value?.includes(defaultStartValue)
          ? setFieldValue(evt.target.value)
          : setFieldValue(`${defaultStartValue}${evt.target.value}`);
      } else {
        setFieldValue(evt.target.value);
      }
    };

    const onBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      const val = evt.target.value || "";
      window.setTimeout(() => {
        if (defaultStartValue) {
          onChange({
            target: {
              name: props.name,
              value: val?.replaceAll(defaultStartValue, ""),
            },
          });
        }
        onChange({
          target: {
            name: props.name,
            value: props.type === "number" ? parseInt(val, 10) : val,
          },
        });
      }, 0);
    };
    // Will set depending on the performance props
    const performanceProps = disablePerformance
      ? {
          ...field,
          value: loading ? "Loading..." : fieldValue,
        }
      : {
          ...field,
          value: loading ? "Loading..." : fieldValue,
          onChange: isSetValueOnBlur ? onChangeHandler : onChange,
          ...(isSetValueOnBlur ? { onBlur, onFocus: onBlur } : {}),
        };

    return (
      <TextField
        variant="filled"
        {...otherProps}
        InputProps={InputProps}
        error={error}
        helperText={helperText}
        {...performanceProps}
        sx={{ ...textFieldStyle, ...sx }}
      />
    );
  }
);

export default PerformantTextField;
