import { useEffect, useState } from "react";
import { TextField, Autocomplete, Chip, TextFieldProps } from "@mui/material";
import { pxToRem, textFieldStyle } from "appConstants";

type TagsInputPropType = {
  availableTags: string[]; // INFO: For suggestions
  addedTags: string[];
  textFieldProps?: TextFieldProps;
  onChange: (tags: string[]) => void;
};

const TagsInput = (props: TagsInputPropType) => {
  const {
    availableTags = [],
    addedTags,
    textFieldProps = {}, // Provide default value as an empty object
    onChange,
  } = props;

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { sx: textFieldSx, ...textFieldPropObj } = textFieldProps || {};

  useEffect(() => {
    if (addedTags?.length) {
      setSelectedTags(addedTags);
    }
  }, [addedTags]);

  const handleBlur = () => {
    onChange(selectedTags);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={availableTags}
      value={selectedTags}
      onChange={(event, newValue) => setSelectedTags(newValue)}
      onBlur={handleBlur}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="filled" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Tags"
          sx={{ ...textFieldStyle, ...textFieldSx }}
          InputProps={{
            ...params.InputProps,
            sx: {
              py: "0 !important",
              pl: `${pxToRem(60)} !important`,
            },
          }}
          {...textFieldPropObj}
        />
      )}
    />
  );
};

export default TagsInput;
