import { useEffect, useState } from "react";
import { Box, BoxProps, Grid, SxProps, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import styled from "@emotion/styled";
import PageBody from "components/PageBody";
import {
  pageBottomSpacing,
  gutterSpace,
  pxToRem,
  formRightPad,
  tabMenuStyle,
} from "appConstants";
import { TabmenuItemsAndContent } from "types";

type TabMenuPropType = {
  menuItemsAndContent: TabmenuItemsAndContent[];
  sx?: object;
  onTabChange?: (id: string) => void;
  isFullPageTabs?: boolean;
  isFormRightPad?: boolean;
  tabValue?: string;
  isTabMenuV2?: boolean;
  tabsSx?: SxProps;
  tabPannelSx?: SxProps;
  tabPannelParentGridSx?: SxProps;
};

const TabsWrapper = styled(Box)<BoxProps>();

const TabMenu = (props: TabMenuPropType) => {
  const {
    menuItemsAndContent,
    sx,
    onTabChange,
    isFullPageTabs = false,
    isFormRightPad = false,
    tabValue,
    isTabMenuV2 = false,
    tabsSx = {},
    tabPannelSx = {},
    tabPannelParentGridSx = {},
  } = props;

  const [value, setValue] = useState(menuItemsAndContent[0].id);

  const combinedTabsSx = { ...tabMenuStyle, ...tabsSx };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
      if (onTabChange) {
        onTabChange(tabValue);
      }
    }
  }, [tabValue]);

  if (isTabMenuV2) {
    return (
      <>
        <Grid item xs="auto">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={combinedTabsSx}
          >
            {menuItemsAndContent?.map((item) => (
              <Tab key={item.id} label={item.value} value={item.id} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} sx={tabPannelParentGridSx}>
          {menuItemsAndContent?.map((item) => (
            <PageBody
              isFullPageView={isFullPageTabs}
              sx={{
                ...(item.hasGridOnly && { overflow: "hidden !important" }),
              }}
            >
              <TabPanel
                key={item.id}
                value={value}
                id={item.id}
                sx={{
                  paddingBottom: isFullPageTabs && pageBottomSpacing,
                  paddingRight: isFormRightPad && formRightPad,
                  ...tabPannelSx,
                }}
              >
                <Box my={gutterSpace}>{item.content}</Box>
              </TabPanel>
            </PageBody>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <TabsWrapper sx={sx}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {menuItemsAndContent?.map((item) => (
          <Tab
            key={item.id}
            label={item.value}
            value={item.id}
            sx={{ fontSize: pxToRem(14), fontWeight: 600 }}
          />
        ))}
      </Tabs>
      {menuItemsAndContent?.map((item) => (
        <PageBody key={`page-body-${item.id}`} isFullPageView={isFullPageTabs}>
          <TabPanel
            key={`tab-panel-${item.id}`}
            value={value}
            id={item.id}
            sx={{
              paddingBottom: isFullPageTabs && pageBottomSpacing,
              paddingRight: isFormRightPad && formRightPad,
            }}
          >
            <Box my={gutterSpace}>{item.content}</Box>
          </TabPanel>
        </PageBody>
      ))}
    </TabsWrapper>
  );
};

export default TabMenu;
