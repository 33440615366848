import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_CUSTOMER,
  GET_BUYERS,
  GET_CUSTOMERS_BY_ASSOCIATION_ID,
  TAGS_URL,
} from "utils/urls";
import { getRequest, postRequest } from "utils/axiosClient";
import { ERROR_MESSAGES, STATUS_CODE } from "appConstants";
import AppError from "utils/AppError";
import { GetAllPayload, GetAssociatedCustomersPayload } from "types";

export const getBuyers = createAsyncThunk(
  GET_BUYERS,
  async (payload: GetAllPayload) => {
    try {
      const response = await getRequest<any>(GET_BUYERS, {
        params: payload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getBuyers,
        error.response.data?.message
      );
    }
  }
);

export const createCustomer = createAsyncThunk(
  CREATE_CUSTOMER,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(CREATE_CUSTOMER, payload);

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createCustomer,
        error.response.data?.message
      );
    }
  }
);

// INFO: AssociationId means if buyer is associated with any other buyer
export const getAssociatedCustomers = createAsyncThunk(
  GET_CUSTOMERS_BY_ASSOCIATION_ID,
  async (payload: GetAssociatedCustomersPayload) => {
    try {
      const { customerId, ...params } = payload || {};
      const response = await getRequest<any>(
        `${GET_CUSTOMERS_BY_ASSOCIATION_ID}/${customerId}`,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssociatedCustomers,
        error.response.data?.message
      );
    }
  }
);

export const getTags = createAsyncThunk(TAGS_URL, async () => {
  try {
    const response = await getRequest<any>(TAGS_URL);
    return response.data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getTags, error.response.data?.message);
  }
});
