import { DispatchStatus } from "appConstants/inventory";

export const personnelSuccessMessages = {
  personnelCreated: "Success! Personnel Created",
  personnelUpdated: "Success! Personnel Updated",
  personnelBulkUpload: "Success! Personnel Bulk upload successful",
  notifyEmailSuccess: "Notification email sent successfully.",
};

export const inventorySuccessMessages = {
  createAsset: "The Asset was created successfully",
  editAsset: "The Asset was updated successfully",
  createTemplate: "The template was created successfully",
  editTemplate: "The template was updated successfully",
  scanSuccess: "Success! Scan completed with no errors",
  assignRole: "Success! User's Role(s) has been updated successfully",
  createPurchaseOrder: "Success! Purchase Order created",
  editPurchaseOrder: "Success! Purchase Order updated.",
};

export const dispatchSuccessMessages = {
  createDispatch: "Success! Assets added for dispatch",
  editDispatch: "Success! Dispatch updated",
  addNote: "Success! Note added",
  [DispatchStatus.Cancel]: "Success! Dispatch Cancelled",
  [DispatchStatus.Completed]: "Success! Dispatch Completed",
};

export const dealershipSuccessMessages = {
  createDealership: "Success! Dealership Created",
  updateDealership: "Success! Dealership Updated",
  createSubgroup: "Success! Subgroup Created",
};

export const quoteSuccessMessages = {
  quoteCreated: "Success! Quote Created",
  quoteUpdated: "Success! Quote Updated",
  quoteVoided: "Success! Quote Voided",
  takeDeposit: "Deposit taken successfully",
  tradeAssetCreated: "Success! Trade Asset Created",
};

export const interestSuccessMessages = {
  interestCreated: "Success! Interest Created",
  interestUpdated: "Success! Interest Updated",
};
