import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import TopIndicator from "components/TopIndicator/TopIndicator";
import useAppMainHeader from "./useAppMainHeader";

import { MenuRounded } from "@mui/icons-material";
import {
  appMainHeaderStyle,
  pxToRem,
  rightSideBarStyle,
  topIndicatorBar,
} from "appConstants/styles";
import { MENU_ITEMS } from "appConstants/routes";
import React from "react";
import AvatarWrapper from "components/Avatar";
import LogoutIcon from "components/LogoutIcon";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AppMainHeaderWrapperType } from "types/headerCmp";
import AppLogo from "images/fun-town-rv.svg";

const AppMainHeaderWrapper = styled(Stack)<StackProps>();

const AppMainHeader = (props: AppMainHeaderWrapperType) => {
  const {
    activeMenuItem,
    moduleColor,
    moduleSubColor,
    headerMenuOpen,
    anchorEl,
    leftPan,
    rightPan,
    cornerPan,
    NavSubLinks,
    handleHeaderMenuClick,
    handleHeaderMenuClose,
    handleNavigateToRoute,
    handleLogout,
  } = useAppMainHeader(props);

  return (
    <>
      <TopIndicator
        backgroundColor={moduleColor}
        sx={{ mb: topIndicatorBar.spaceBottom }}
      />
      <AppMainHeaderWrapper
        flexDirection={"row"}
        sx={appMainHeaderStyle}
        pr={pxToRem(25)}
        mb={pxToRem(18)}
      >
        {/* Main Navigation [Start] */}
        <Box>
          {/* Menu button */}
          <Button
            aria-controls={headerMenuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={headerMenuOpen ? "true" : undefined}
            onClick={handleHeaderMenuClick}
            sx={{
              ...appMainHeaderStyle.btnMainMenuStyle,
              "&, &:hover": {
                backgroundColor: moduleColor || "appModules.50",
              },
            }}
          >
            {!headerMenuOpen && <MenuRounded />}
            {headerMenuOpen && <CloseRoundedIcon />}
          </Button>
          {/* Menu List */}
          <Menu
            id="appMainNenu"
            anchorEl={anchorEl}
            open={headerMenuOpen}
            onClose={handleHeaderMenuClose}
            MenuListProps={{
              "aria-labelledby": "appMainNenu",
            }}
            sx={appMainHeaderStyle.mainMenuDropdownStyle}
          >
            {MENU_ITEMS?.map((item, index) => {
              if (item?.showItem === false) return null;

              const isActive = activeMenuItem?.link === item?.link;

              return (
                <MenuItem
                  key={index}
                  onClick={() => handleNavigateToRoute(item?.link, true)}
                  className={`mainMenuItem ${isActive ? "active" : ""}`}
                  sx={{
                    ".MuiSvgIcon-root": {
                      color: item?.moduleColor,
                    },

                    //hover
                    "&:hover, &.active": {
                      backgroundColor: item?.moduleColor,
                    },
                  }}
                >
                  {item?.moduleIcon && React.createElement(item?.moduleIcon)}
                  <Typography>{item?.label}</Typography>
                </MenuItem>
              );
            })}

            <MenuItem className="otherMenuItem" onClick={handleLogout}>
              <LogoutIcon /> Logout
            </MenuItem>

            <Divider />

            <MenuItem className="otherMenuItem userTile">
              <Stack direction={"row"} gap={pxToRem(8)} alignItems={"center"}>
                <Box>
                  <AvatarWrapper userName="Michael Smith" />
                </Box>
                <Box>
                  <Typography className="userName">Michael Smith</Typography>
                  <Typography className="userEmail">
                    michaelsmith12@gmail.com
                  </Typography>
                </Box>
              </Stack>
            </MenuItem>
          </Menu>
        </Box>
        {/* Main Navigation [END] */}
        {/* Center Block [Start] */}
        <Box
          display="flex"
          sx={{
            direction: "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${AppLogo})`,
              backgroundSize: "cover",
              height: pxToRem(33),
              width: pxToRem(113),
              mr: pxToRem(13),
            }}
          />
        </Box>
        <Box flex={1}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"100%"}
            borderBottom={`${pxToRem(1)} solid`}
            borderColor="greyVariantAlt.800"
          >
            {leftPan ? (
              <Box>{leftPan}</Box>
            ) : (
              <Stack direction={"row"} gap={pxToRem(7)}>
                {NavSubLinks.map((linkItem, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleNavigateToRoute(linkItem.link)}
                    sx={{
                      backgroundColor: linkItem.isActive
                        ? moduleColor
                        : "transparent",
                      color: linkItem.isActive ? "grey.A400" : "grey.50",
                      borderRadius: pxToRem(4),
                      height: pxToRem(31),
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: !linkItem.isActive && moduleSubColor,
                      },
                    }}
                  >
                    <ListItemText primary={linkItem.label} />
                  </ListItem>
                ))}
              </Stack>
            )}
            {rightPan && <Box>{rightPan}</Box>}
          </Stack>
        </Box>
        {/* Center Block [END] */}
        {/* Corner Block [Start] */}
        {cornerPan && <Box sx={rightSideBarStyle}>{cornerPan}</Box>}
        {/* Corner Block [END] */}
      </AppMainHeaderWrapper>
    </>
  );
};

export default AppMainHeader;
