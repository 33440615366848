import { CustomerState } from "./customer.model";

export const getBuyersFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.buyers = action.payload?.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getBuyersRejected = (state: CustomerState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const createCustomerFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.createdCustomer = action.payload;
};

export const createCustomerRejected = (state: CustomerState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getAssociatedCustomersFullfilled = (
  state: CustomerState,
  action
) => {
  state.isLoading = false;
  state.associatedCustomers = action.payload?.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getAssociatedCustomersRejected = (
  state: CustomerState,
  action
) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getTagsFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.tags = action.payload?.tags;
};

export const getTagsRejected = (state: CustomerState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};
