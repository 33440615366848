import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_LOAN_TYPES_URL,
  GET_PAYMENT_TYPES_URL,
  QUOTE_URL,
  GET_QUOTES_URL,
  GET_QUOTE_STATUSES_URL,
  VOID_QUOTE_URL,
  TAKE_DEPOSIT_URL,
  GET_FEES_URL,
  GET_TAXES_URL,
} from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import { ERROR_MESSAGES, JSON_HEADER, STATUS_CODE } from "appConstants";
import AppError from "utils/AppError";
import {
  GetFeesPayload,
  GetQuoteByIDPayload,
  GetQuotesPayload,
  GetTaxesPayload,
  TakeDeposit,
} from "types/finance";

const typePrefix = {
  CREATE: "createQuote",
  EDIT: "editQuote",
};

export const getQuotes = createAsyncThunk(
  GET_QUOTES_URL,
  async (payload: GetQuotesPayload) => {
    try {
      const response = await getRequest<any>(GET_QUOTES_URL, {
        params: payload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getQuotes,
        error.response.data?.message
      );
    }
  }
);

export const getQuoteById = createAsyncThunk(
  QUOTE_URL,
  async (payload: GetQuoteByIDPayload) => {
    try {
      const response = await getRequest<any>(QUOTE_URL + "/" + payload.id);
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getQuotes,
        error.response.data?.message
      );
    }
  }
);

export const createQuote = createAsyncThunk(
  typePrefix.CREATE,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(QUOTE_URL, payload, JSON_HEADER);

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createQuote,
        error.response.data?.message
      );
    }
  }
);

export const editQuote = createAsyncThunk(
  typePrefix.EDIT,
  async (payload: any) => {
    try {
      const response = await putRequest<any>(QUOTE_URL, payload, JSON_HEADER);

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createQuote,
        error.response.data?.message
      );
    }
  }
);

export const voidQuote = createAsyncThunk(
  VOID_QUOTE_URL,
  async (payload: any) => {
    try {
      const response = await putRequest<any>(
        VOID_QUOTE_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.voidQuote,
        error.response.data?.message
      );
    }
  }
);

export const getFinanceStatuses = createAsyncThunk(
  GET_QUOTE_STATUSES_URL,
  async () => {
    try {
      const response = await getRequest<any>(GET_QUOTE_STATUSES_URL);
      const statuses = response.data?.filter((status) => status.isActive);
      return statuses;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getFinanceStatuses,
        error.response.data?.message
      );
    }
  }
);

export const getLoanTypes = createAsyncThunk(GET_LOAN_TYPES_URL, async () => {
  try {
    const response = await getRequest<any>(GET_LOAN_TYPES_URL);
    const loanTypes = response.data?.filter((loanType) => loanType.isActive);
    return loanTypes;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getLoanTypes,
      error.response.data?.message
    );
  }
});

export const getPaymentTypes = createAsyncThunk(
  GET_PAYMENT_TYPES_URL,
  async () => {
    try {
      const response = await getRequest<any>(GET_PAYMENT_TYPES_URL);
      const paymentTypes = response.data?.filter(
        (paymentType) => paymentType.isActive
      );
      return paymentTypes;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPaymentTypes,
        error.response.data?.message
      );
    }
  }
);

export const takeDeposit = createAsyncThunk(
  TAKE_DEPOSIT_URL,
  async (payload: TakeDeposit) => {
    try {
      const response = await putRequest<any>(
        TAKE_DEPOSIT_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return response;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.takeDeposit,
        error.response.data?.message
      );
    }
  }
);

export const getFeeTypes = createAsyncThunk(
  GET_FEES_URL,
  async (payload: GetFeesPayload) => {
    try {
      const response = await getRequest<any>(GET_FEES_URL, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getFees, error.response.data?.message);
    }
  }
);

export const getTaxTypes = createAsyncThunk(
  GET_TAXES_URL,
  async (payload: GetTaxesPayload) => {
    try {
      const response = await getRequest<any>(GET_TAXES_URL, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getTaxes, error.response.data?.message);
    }
  }
);
