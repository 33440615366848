import FORM_FIELDS from "./formFields";

export enum SUMMARY_CARD_MODE {
  CREATE = "create",
  DUPLICATE = "duplicate",
}

export enum ASSET_IMAGE_SELECTION_MODE {
  CREATE = "create",
  SUMMARY = "summary",
}

export const ASSET_STOCK_NUMBER_LENGTH = 6;

export enum ASSET_UNIT_TYPE {
  ATV = "ATV",
  AUTO = "AUTO",
  BOAT = "BOAT",
  CT_EXP = "CT/EXP",
  FW = "FW",
  M_CYCLE = "M CYCLE",
  MH = "MH",
  TH = "TH",
  TRK_CAMP = "TRK CAMP",
  TT = "TT",
  UT = "UT",
}

export enum ASSET_TYPE {
  MOTORIZED = "Motorized",
  TOWABLE = "Towable",
  FIXED_ASSET = "Fixed Asset",
}

export enum DispatchStatus {
  New = 1,
  Cancel = 2,
  Completed = 3,
}

export enum PurchasingViewMode {
  TEMPLATES = "templates",
  PURCHASE_ORDER = "PurchaseOrder",
}

export const ASSET_DEFAULT_LOCATION = "CORP";

export enum CREATE_ASSET_TAB_IDS {
  UNIT_INFO_PRICING = "unitInfo&Pricing",
  OTHER_PRICING = "otherPricing",
  METADATA_DESCRIPTION = "metadata&Description",
  LOCATION = "location",
  ADD_IMAGES = "addImages",
  MANAGE_IMAGES = "manageImages",
}

export enum TITLE_STATUS_NAME {
  NEW = "New",
  USED = "Used",
}

export enum UNIT_STATUS_NAME {
  BUY_BACK = "BUY BACK",
  DAMAGED = "DAMAGED",
  FACTORY_REVIEW = "FACTORY REVIEW",
  FLEET_PENDING = "FLEET PENDING",
  IN_SERVICE = "IN SERVICE",
  ORDERED = "ORDERED",
  PRE_PDI = "PRE PDI",
  PURCHASED = "PURCHASED",
  QC_NEEDED = "QC NEEDED",
  READY_FOR_SALE = "READY FOR SALE",
  RESTOCK = "RESTOCK",
  RV_SHOW_BACKUP = "RV SHOW BACKUP",
  RV_SHOW_UNIT = "RV SHOW UNIT",
  RVASAP = "RVASAP",
  SALE_PENDING = "SALE PENDING",
  SHIPPED = "SHIPPED",
  SHOWROOM = "SHOWROOM",
  SOLD = "SOLD",
  STORETOSTORE = "STORETOSTORE",
  TEMPLATE = "TEMPLATE",
  TRADE_IN = "TRADE IN",
  TRANSFER = "TRANSFER",
  WHOLESALE_USED = "WHOLESALE - USED",
  EAST_STOCK = "EAST STOCK",
}
