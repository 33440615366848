import ProtectedRoute from "ProtectedRoute";
import { APP_ROUTES } from "appConstants/routes";
import loadable from "@loadable/component";
import { Route, Switch } from "react-router-dom";
import { PERMISSION } from "appConstants/security";

const routes = [
  {
    id: "home",
    path: APP_ROUTES.Home,
    component: loadable(() => import("./containers/Home")),
    exact: true,
    isProtected: true,
  },
  {
    id: "login",
    path: APP_ROUTES.Login,
    component: loadable(() => import("./containers/login")),
    exact: true,
  },
  {
    id: "logout",
    path: APP_ROUTES.Logout,
    component: loadable(() => import("./components/Logout")),
    exact: true,
  },
  {
    id: "forgotPassword",
    path: APP_ROUTES.ForgotPassword,
    component: loadable(() => import("./containers/resetpassword")),
    exact: true,
  },
  {
    id: "authenticateCode",
    path: APP_ROUTES.AuthenticateCode,
    component: loadable(
      () => import("./containers/authenticationcodeverification")
    ),
    exact: true,
  },
  {
    id: "viewPersonnel",
    path: APP_ROUTES.ViewSecurityPersonnel,
    component: loadable(() => import("./containers/security/ViewPersonnel")),
    exact: true,
    isProtected: true,
  },
  {
    id: "personnel",
    path: APP_ROUTES.SecurityPersonnel,
    component: loadable(() => import("./containers/security")),
    exact: true,
    isProtected: true,
  },
  {
    id: "assignRole",
    path: APP_ROUTES.AssignRole,
    component: loadable(() => import("./containers/security/roles/assignrole")),
    exact: true,
    isProtected: true,
  },
  {
    id: "editPersonnel",
    path: APP_ROUTES.EditSecurityPersonnel,
    component: loadable(
      () => import("./containers/security/PersonnelForm/EditPersonnel")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "createRole",
    path: APP_ROUTES.CreateRole,
    component: loadable(() => import("./containers/security/roles/createrole")),
    exact: true,
    isProtected: true,
    permissionKey: PERMISSION.ROLES_CREATE,
  },
  {
    id: "editRole",
    path: APP_ROUTES.EditRole,
    component: loadable(() => import("./containers/security/roles/createrole")),
    exact: true,
    isProtected: true,
    permissionKey: PERMISSION.ROLES_UPDATE,
  },
  {
    id: "viewRoles",
    path: APP_ROUTES.ViewRoles,
    component: loadable(() => import("./containers/security/roles")),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewRole",
    path: APP_ROUTES.ViewRole,
    component: loadable(() => import("./containers/security/roles/viewrole")),
    exact: true,
    isProtected: true,
  },
  {
    id: "mergePersonnel",
    path: APP_ROUTES.MergeSecurityPersonnel,
    component: loadable(() => import("./containers/security/MergePersonnel")),
    exact: true,
    isProtected: true,
  },
  {
    id: "exampleComponents",
    path: APP_ROUTES.ExampleComp,
    component: loadable(() => import("./examples/components")),
    exact: true,
    isProtected: true,
  },
  {
    id: "reports",
    path: APP_ROUTES.Reports,
    component: loadable(() => import("./containers/Reports")),
    exact: true,
    isProtected: true,
  },
  {
    id: "timeClock",
    path: APP_ROUTES.TimeClock,
    component: loadable(() => import("./containers/timeclock")),
    exact: true,
    isProtected: true,
  },
  {
    id: "assetRecords",
    path: APP_ROUTES.AssetRecords,
    component: loadable(() => import("./containers/inventory/asset")),
    exact: true,
    isProtected: true,
  },
  {
    id: "dealershipRecords",
    path: APP_ROUTES.DealershipRecords,
    component: loadable(() => import("./containers/dealership")),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/dealership/dealershipContext")
    ),
  },
  {
    id: "createAsset",
    path: APP_ROUTES.CreateAsset,
    component: loadable(
      () => import("./containers/inventory/asset/createasset")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/inventory/inventoryContext")
    ),
  },
  {
    id: "editAsset",
    path: APP_ROUTES.EditAsset,
    component: loadable(
      () => import("./containers/inventory/asset/createasset")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/inventory/inventoryContext")
    ),
  },
  {
    id: "createDealership",
    path: APP_ROUTES.CreateDealership,
    component: loadable(
      () => import("./containers/dealership/createDealership")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/dealership/dealershipContext")
    ),
  },
  {
    id: "editDealership",
    path: APP_ROUTES.EditDealership,
    component: loadable(
      () => import("./containers/dealership/createDealership")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/dealership/dealershipContext")
    ),
  },
  {
    id: "viewDealership",
    path: APP_ROUTES.ViewDealership,
    component: loadable(() => import("./containers/dealership/viewDealership")),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/dealership/dealershipContext")
    ),
  },
  {
    id: "dealershipSettingGeneralInfo",
    path: APP_ROUTES.DealershipSettingGeneralInfo,
    component: loadable(
      () => import("./containers/DealershipSetting/GeneralInfo")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "dealershipSettingStatuses",
    path: APP_ROUTES.DealershipSettingStatuses,
    component: loadable(
      () => import("./containers/DealershipSetting/Statuses")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "dealershipSettingVendors",
    path: APP_ROUTES.DealershipSettingVendors,
    component: loadable(() => import("./containers/DealershipSetting/Vendors")),
    exact: true,
    isProtected: true,
  },
  {
    id: "dealershipSettingAddOns",
    path: APP_ROUTES.DealershipSettingAddOns,
    component: loadable(() => import("./containers/DealershipSetting/AddOns")),
    exact: true,
    isProtected: true,
  },
  {
    id: "dealershipSettingAccounts",
    path: APP_ROUTES.DealershipSettingAccounts,
    component: loadable(
      () => import("./containers/DealershipSetting/Accounts")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "createSubGroupDealership",
    path: APP_ROUTES.CreateSubGroupDealership,
    component: loadable(
      () => import("./containers/dealership/createSubGroupDealership")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/dealership/dealershipContext")
    ),
  },
  {
    id: "viewAsset",
    path: APP_ROUTES.ViewAsset,
    component: loadable(() => import("./containers/inventory/asset/viewasset")),
    exact: true,
    isProtected: true,
  },
  {
    id: "duplicateAsset",
    path: APP_ROUTES.DuplicateAsset,
    component: loadable(
      () => import("./containers/inventory/asset/duplicateasset/DuplicateAsset")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/inventory/inventoryContext")
    ),
  },
  {
    id: "accountingOperations",
    path: APP_ROUTES.AccountingOperations,
    component: loadable(
      () =>
        import(
          "./containers/inventory/asset/AccountingOperations/CreateTransaction/UnitInfo"
        )
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewAssetHistory",
    path: APP_ROUTES.ViewAssetHistory,
    component: loadable(
      () => import("./containers/inventory/asset/viewassethistory")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewAssetHistoryDetails",
    path: APP_ROUTES.ViewAssetHistoryDetails,
    component: loadable(
      () => import("./containers/inventory/asset/viewassethistory/details")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "transactionListing",
    path: APP_ROUTES.TransactionListing,
    component: loadable(
      () =>
        import(
          "./containers/inventory/asset/AccountingOperations/TransactionListing"
        )
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "dispatchRecords",
    path: APP_ROUTES.DispatchRecords,
    component: loadable(() => import("./containers/inventory/dispatch")),
    exact: true,
    isProtected: true,
  },
  {
    id: "createDispatch",
    path: APP_ROUTES.CreateOrEditDispatch,
    component: loadable(
      () => import("./containers/inventory/dispatch/createdispatch")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewDispatch",
    path: APP_ROUTES.ViewDispatch,
    component: loadable(
      () => import("./containers/inventory/dispatch/viewdispatch")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewDispatchHistory",
    path: APP_ROUTES.ViewDispatchHistory,
    component: loadable(
      () => import("./containers/inventory/dispatch/viewhistory")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewDispatchCalendar",
    path: APP_ROUTES.ViewDispatchCalendar,
    component: loadable(
      () => import("./containers/inventory/dispatch/calendar")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "purchasing",
    path: APP_ROUTES.PurchasingListing,
    component: loadable(() => import("./containers/inventory/purchasing")),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewPurchase",
    path: APP_ROUTES.ViewPurchaseItem,
    component: loadable(
      () => import("./containers/inventory/purchasing/viewPurchasing")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "viewTemplate",
    path: APP_ROUTES.ViewTemplateItem,
    component: loadable(
      () => import("./containers/inventory/purchasing/viewTemplate")
    ),
    exact: true,
    isProtected: true,
  },
  {
    id: "createPurchase",
    path: APP_ROUTES.CreatePurchaseOrder,
    component: loadable(
      () => import("./containers/inventory/purchasing/createPurchase")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/inventory/inventoryContext")
    ),
  },
  {
    id: "editPurchase",
    path: APP_ROUTES.EditPurchaseOrder,
    component: loadable(
      () => import("./containers/inventory/purchasing/createPurchase")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/inventory/inventoryContext")
    ),
  },
  {
    id: "financeQuotes",
    path: APP_ROUTES.FinanceQuotes,
    component: loadable(() => import("./containers/finance/quotes")),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/finance/financeContext")
    ),
  },
  {
    id: "financeCreateQuote",
    path: APP_ROUTES.FinanceCreateQuote,
    component: loadable(
      () => import("./containers/finance/quotes/CreateQuote")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/finance/financeContext")
    ),
  },
  {
    id: "financeEditQuote",
    path: APP_ROUTES.FinanceEditQuote,
    component: loadable(
      () => import("./containers/finance/quotes/CreateQuote")
    ),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/finance/financeContext")
    ),
  },
  {
    id: "financeViewQuote",
    path: APP_ROUTES.FinanceViewQuote,
    component: loadable(() => import("./containers/finance/quotes/ViewQuote")),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/finance/financeContext")
    ),
  },
  {
    id: "financeDeals",
    path: APP_ROUTES.FinanceDeals,
    component: loadable(() => import("./containers/finance/deals")),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/finance/financeContext")
    ),
  },
  {
    id: "financeDeliveries",
    path: APP_ROUTES.FinanceDeliveries,
    component: loadable(() => import("./containers/finance/deliveries")),
    exact: true,
    isProtected: true,
    contextProvider: loadable(
      () => import("./containers/finance/financeContext")
    ),
  },
  {
    id: "notFound",
    path: "*",
    component: loadable(() => import("./containers/notfound")),
  },
];

const Routes = () => (
  <Switch>
    {routes.map((route) =>
      !route?.isProtected ? (
        <Route
          key={route.id}
          path={route.path}
          exact={!!route.exact}
          component={route.component}
        />
      ) : (
        <ProtectedRoute
          key={route.id}
          path={route.path}
          exact={!!route.exact}
          permissionKey={route.permissionKey}
          component={(props) =>
            route.contextProvider ? (
              <route.contextProvider>
                <route.component {...props} />
              </route.contextProvider>
            ) : (
              <route.component {...props} />
            )
          }
        />
      )
    )}
  </Switch>
);

export default Routes;
